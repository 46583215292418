<template>
  <div class="modal not-sub-join fade" id="join-not-sub" tabindex="-1" role="dialog" aria-labelledby="not-sub-join" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header text-center border-bottom-0 pb-0">
          <button type="button" class="close position-absolute border-0 outline-none" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <p class="body-msg">
            Để xem được nội dung này, và toàn bộ video chuyên đề, tài liệu, trắc nghiệm, bạn hãy đăng ký gói cước Thư viện nội dung của Vilearn nhé!
          </p>
          <div class="d-flex align-items-center justify-content-center group-actions">
            <div class="btn-action btn-action-gray mx-2">
              Xem thêm
            </div>
            <div class="btn-action mx-2">
              Đăng ký gói Sub
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .modal {
    .modal-header {
      padding-top: 6px;
      padding-bottom: 6px;
      .close {
        top: 8px;
        right: 10px;
        z-index: 9999;
      }
    }
    .modal-body {
      .group-actions {
        gap: 10px;
      }
      .body-msg {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #75777b;
        margin: 0 auto 16px;
      }
      .btn-action {
        background: #ffa216;
        border-radius: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 9px;
        width: 144px;
        cursor: pointer;
        &.btn-action-gray {
          background: #c4c4c4;
        }
      }
    }
  }
</style>
