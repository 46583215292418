<template>
  <div class="tab-content-topic">
    <div class="m-type-block" v-if="learning.length">
      <p class="m-type-title">
        Đang học ({{ learning.length }})
      </p>
      <div class="list-cards row mt-3 mt-xl-4">
        <TopicItem class="col col-12 mb-3 mb-xl-4" v-for="(item, ind) in learning" :key="ind" :view-list="false" :item="item" :my-library="true" type="learning"/>
        <NoData class="col-12" v-if="!learning.length" text="Bạn chưa theo dõi chuyên đề nào" />
      </div>
    </div>
    <div class="m-type-block">
      <p class="m-type-title">
        Đang theo dõi ({{ following.length }})
      </p>
      <div v-if="loading" class="list-cards row mt-3 mt-xl-4">
        <SkeletonItem class="col col-12 mb-3 mb-xl-4" :view-list="false" />
      </div>
      <div v-else-if="following.length" class="list-cards row mt-3 mt-xl-4">
        <TopicItem v-for="(item, ind) in following" :key="ind" class="col col-12 mb-3 mb-xl-4" :view-list="false" :item="item" :my-library="true" type="learning" />
      </div>
      <NoData v-else text="Bạn chưa theo dõi chuyên đề nào"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopicItem from '~/components/library-v2/topic/item.vue'
import SkeletonItem from '~/components/skeleton/topic/item.vue'
import NoData from '~/components/nodata/new.vue'
import Resource from '~/common/api/resource'
const learningResource = new Resource('library/topic/list-learning')
const followResource = new Resource('library/topic/listFollow')
export default {
  components: {
    TopicItem,
    SkeletonItem,
    NoData
  },
  data () {
    return {
      learning: [],
      following: [],
      pageLearning: 1,
      pageFollowing: 1,
      loading: false
    }
  },
  computed: {
    ...mapState([
      'token'
    ])
  },
  created () {
    // this.loadLearning()
    this.loadFollow()
  },
  methods: {
    async loadLearning () {
      if (this.pageLearning === 1) {
        this.learning = []
      }
      const { data } = await learningResource.list({ page: this.pageLearning, limit: 15 }, this.token)
      this.learning.push(...data)
    },
    async loadFollow () {
      if (this.loading) { return }
      this.loading = true
      if (this.pageFollowing === 1) {
        this.following = []
      }
      const { data } = await followResource.list({ page: this.pageFollowing, limit: 15 }, this.token)
      this.following.push(...data)
      this.loading = false
    },
    handleUnfollow () {
      this.loadFollow()
    }
  }
}
</script>

<style lang="scss" scoped>
  .tab-content-topic{
    .m-type-block {
      margin-bottom: 1.3rem;
      @media (max-width: 1366px) {
      }
      @media (max-width: 1200px) {
      }
      @media (max-width: 992px) {
      }
      @media (max-width: 768px) {
      }
      @media (max-width: 576px) {
        margin-bottom: 1rem;
      }
      .m-type-title {
        line-height: 1.3;
        color: #888888;
        font-size: 1.125rem;
        margin-bottom: 1.25rem;
        @media (max-width: 1366px) {
          font-size: 1.125rem;
          margin-bottom: .75rem;
        }
        @media (max-width: 1200px) {
        }
        @media (max-width: 992px) {
        }
        @media (max-width: 768px) {
        }
        @media (max-width: 576px) {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
</style>
