<template>
  <div class="list-content-tab" :class="type" v-if="collections.length">
    <div class="list-tab-header mb-2 mb-xl-3 d-flex align-items-center justify-content-between" v-if="type === 'topic'">
      <span class="tab-title color-darkgrey font-weight-bold" id="seminar-conent-sp">Nội dung</span>
      <div class="dropdown content-selector">
        <p class="btn d-flex align-items-center justify-content-between selected-data bg-white mb-0 class-link d-block dropdown-toggle color-darkgrey" type="button" id="contentSelects" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tất cả các bài học
          <img src="~/assets/icons/library/chevron-dropdown.svg" alt="">
        </p>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="contentSelects">
          <div class="dropdown-item pointer" @click="showCollapse(-1)">Tất cả các bài học</div>
          <div class="dropdown-item pointer" v-for="(lesson, ind) in collections" :key="ind" :class="{'d-none': lesson.type !== 'VIDEO'}" @click="showCollapse(ind)">{{lesson.name}}</div>
        </div>
      </div>
    </div>
    <div class="each-unit-content mt-2 mt-xl-3">
      <template v-for="(item,ind) in collections">
        <div :key="ind">
          <div v-if="item.type === 'CHAPTER'" class="unit-name mt-2 mt-xl-3 text-primary text-uppercase">{{ item.name }}</div>
          <div v-else-if="item.type === 'LIVESTREAM'" class="each-lesson w-100 overflow-hidden" @click="checkLiveStream(item)">
            <p class="btn btn-lesson-expand border-0 bg-white w-100 d-flex mb-0 align-items-center color-darkgrey py-2" :class="{'py-2': type === 'video'}">
              <img src="~/assets/icons/library/live-stream-icon-red.svg" class="mr-2" alt="" width="35">
              <span class="limit-line-2 white-space-normal text-left">{{ item.name }}</span>
              <span v-if="type==='topic'" class="small-detail">
                <template v-if="item.is_playback">
                  ({{ item.duration | duration }})
                </template>
                <template v-else-if="item.ended">
                  (Đã live {{ item.livestream_at | parseTime('{h}:{i} {d}/{m}/{y}') }})
                </template>
                <template v-else>
                  ({{ item.livestream_at | parseTime('{h}:{i} {d}/{m}/{y}') }})
                </template>
              </span>
            </p>
          </div>
          <div v-else class="each-lesson w-100 overflow-hidden">
            <p class="btn btn-lesson-expand border-0 bg-white w-100 d-flex mb-0 align-items-center color-darkgrey" :class="{'py-2': type === 'video'}" type="button" data-toggle="collapse" :data-target="`#lesson-${ind}`" :aria-expanded="item.id === parentId ? 'true' : 'false'">
              <span class="limit-line-2 white-space-normal text-left">{{item.name}}</span>
              <span class="small-detail" v-if="type==='topic'">({{ item.total_duration_file | duration }})</span>
              <img src="~/assets/icons/library/chevron-dropdown.svg" class="ml-auto expand-icon" alt="">
            </p>
            <div class="collapse lesson-collapse border-top" :class="{'show': item.id === parentId}" :id="`lesson-${ind}`">
              <template v-for="(it, idx) in item.items" >
                <div :key="idx">
                  <div v-if="it.type === 'EXAM'" class="card card-body exercise-lesson lesson-part" >
                    <div class="d-flex align-items-center document" @click="viewExam(it)">
                      <div class="thumb-block position-relative pointer">
                        <img src="~/assets/icons/library/write-grey.svg" class="type-thumb" alt="">
                        <img v-if="it.label" :src="it.label" class="free-tag position-absolute" alt="">
                      </div>
                      <div class="lesson-content d-flex align-items-center color-darkgrey pointer">
                        <div class="display-html">{{ it.name }}</div>
                        <span class="lesson-status ml-auto d-flex align-items-center text-nowrap">
                          <span v-if="it.is_updating">
                            <template v-if="it.release_date">Cập nhật {{ it.release_date | parseTime('{d}/{m}/{y}') }}</template>
                            <template v-else>Đang cập nhật</template>
                          </span>
                          <template v-else>
                            <span v-if="it.is_complete" class="text-success font-weight-bold d-flex h4">{{it.number_correct}} <span class="text-body">/{{it.number_question}}</span></span>
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="it.type === 'PDF'" class="card card-body exercise-lesson lesson-part" >
                    <div class="d-flex align-items-center document" @click="viewDocument(it)">
                      <div class="thumb-block position-relative pointer">
                        <img src="~/assets/icons/library/pdf-grey.svg" class="type-thumb" alt="">
                        <img v-if="it.label" :src="it.label" class="free-tag position-absolute" alt="">
                      </div>
                      <div class="lesson-content d-flex align-items-center color-darkgrey pointer">
                        <div class="display-html">{{ it.name }}</div>
                        <span class="lesson-status ml-auto d-flex align-items-center text-nowrap">
                          <span v-if="it.is_updating">
                            <template v-if="it.release_date">Cập nhật {{ it.release_date | parseTime('{d}/{m}/{y}') }}</template>
                            <template v-else>Đang cập nhật</template>
                          </span>
                          <img v-else src="~/assets/icons/library/download-grey.svg" alt="" class="pointer" @click.stop="viewDocument(it, 'download')">
                          <span v-if="!isSub">
                             <img v-if="!it.is_free " src="~/assets/icons/library/grey-lock.svg" alt="">
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card card-body video-lesson lesson-part" :class="{'is-active': it.id === videoId}">
                    <div class="d-flex align-items-center">
                      <div class="thumb-block position-relative pointer" @click="checkBeforeRedirect(it)">
                        <ImageNuxt :src="it.thumbnail || 'lesson'" class="video-thumb" alt="Card image cap" preset="topic" />
                        <!-- <ImageCustom :src="it.thumbnail" class="video-thumb" :class="type" alt="" type="lesson" /> -->
                        <img src="~/assets/icons/library/white-play-video-btn.svg" class="video-play-btn position-center" alt="">
                        <ImageCustom v-if="it.label" :src="it.label" class="free-tag position-absolute" alt="" />
                        <span class="position-absolute lock-video d-flex" v-if="!isSub">
                          <img v-if="!it.is_free" src="~/assets/icons/library/lock-bg.svg" alt="">
                        </span>
                      </div>
                      <div class="lesson-content d-flex align-items-center color-darkgrey pointer" @click="checkBeforeRedirect(it)">
                        {{ it.name }}
                        <span class="ml-auto d-flex align-items-center font-weight-normal flex-shrink-0" :class="{'lesson-status': it.is_updating}">
                          <span v-if="!isSub">
                            <img v-if="!it.is_free" src="~/assets/icons/library/grey-lock.svg" alt="">
                          </span>
                          <span class="pt-1" v-if="it.is_updating">
                            <template v-if="it.release_date">Cập nhật {{ it.release_date | parseTime('{d}/{m}/{y}') }}</template>
                            <template v-else>Đang cập nhật</template>
                          </span>
                          <span class="pt-1" v-else>{{it.duration | duration}}</span>
                        </span>
                      </div>
                    </div>
                    <div class="extra-block d-flex align-items-center color-darkgrey" v-for="(doc, index) in it.attach_documents" :key="index">
                      <span class="type-n-text d-flex align-items-center pointer" @click="doc.type === 'EXAM' ? viewExam(doc) : viewDocument(doc)">
                        <img v-if="doc.type === 'PDF'" src="~/assets/icons/library/pdf-grey.svg" alt="" class="type-ico">
                        <img v-if="doc.type === 'EXAM'" src="~/assets/icons/library/write-grey.svg" alt="" class="type-ico">
                        <div class="display-html">{{ doc.name }}</div>
                      </span>
                      <span v-if="doc.type === 'EXAM' && doc.is_complete" class="mcq-counter ml-auto flex-shrink-0">
                        <span class="sc">{{doc.number_correct}}</span><span class="total">/{{doc.number_question}}</span>
                      </span>
                      <span v-if="doc.is_updating" class="download-ico ml-auto flex-shrink-0">
                        <template v-if="doc.release_date">Cập nhật {{ doc.release_date | parseTime('{d}/{m}/{y}') }}</template>
                        <template v-else>Đang cập nhật</template>
                      </span>
                      <span v-if="doc.type === 'PDF' && !doc.is_updating" class="download-ico flex-shrink-0" :class="{'ml-2': doc.is_updating, 'ml-auto': !doc.is_updating}">
                        <img src="~/assets/icons/library/download-grey.svg" alt="" class="pointer" @click.stop="viewDocument(doc, 'download')">
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
    <client-only>
      <b-modal id="modal-view-document" no-close-on-backdrop centered hide-footer hide-header body-class="px-4 py-4 position-relative" size="lg" content-class="rounded-sm" v-model="showModalViewDocument">
        <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="showModalViewDocument = false"/>
        <div v-if="documentView">
          <div class="font-weight-bold limit-line-2">{{documentView.name}}</div>
          <div class="library-preview-file mt-2">
            <ViewPdf :link="documentView.url" height="100%"/>
          </div>
        </div>
      </b-modal>
      <b-modal id="modal-view-exam" centered hide-footer hide-header body-class="p-0 position-relative" size="xl" content-class="rounded-sm" v-model="showModalViewExam">
        <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="showModalViewExam = false"/>
        <div v-if="examView">
          <TakeExamComponent :item="examView" @success="showModalViewExam = false" />
        </div>
      </b-modal>
      <b-modal id="modal-preview-exam" no-close-on-backdrop centered hide-footer hide-header body-class="px-5 py-5 position-relative" size="lg" content-class="rounded-sm" v-model="showModalPreviewExam">
        <img class="close pointer position-absolute" src="~/assets/icons/library/close-modal.svg" @click="showModalPreviewExam = false"/>
        <div v-if="examView">
          <div class="h3 font-weight-bold text-break text-body mb-4">{{examView.name}}</div>
          <div class="mb-3 font-weight-bold">
            Thời gian làm bài: <span class="ml-1 text-danger">{{ examView.time }}phút</span>
            <span class="mx-4">|</span>
            Số câu: <span class="ml-1 text-danger">{{examView.number_question}}</span>
          </div>
          <div v-if="examView.description" v-html="examView.description"></div>
          <div class="mt-5" @click="startExam">
            <button class="action px-4 py-2 text-white bg-primary border-0">Làm bài</button>
          </div>
        </div>
      </b-modal>
    </client-only>
  </div>
</template>

<script>
import TakeExamComponent from '~/components/library-v2/quiz/take.vue'
import Resource from '~/common/api/resource'
import { checkAllow } from '~/common/api/check-register'
const fileResource = new Resource('library/file/getBySlug')
const examStartResource = new Resource('library/exam/start')
const livestreamResource = new Resource('library/collection/getBySlug')
const checkSubResource = new Resource('price/sub/user')
export default {
  components: {
    TakeExamComponent
  },
  props: {
    collections: {
      type: Array,
      default () {
        return []
      },
      required: true
    },
    type: {
      type: String,
      default: 'topic'
    },
    parentId: {
      type: String,
      default: ''
    },
    videoId: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showModalViewDocument: false,
      documentView: null,
      showModalPreviewExam: false,
      showModalViewExam: false,
      examView: null,
      parent: null,
      isSub: false
    }
  },
  created () {
    this.checkSubUser()
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    async checkSubUser () {
      const { data } = await checkSubResource.list()
      if (!data.is_expired) {
        this.isSub = true
      } else {
        this.isSub = false
      }
    },
    showCollapse (ind) {
      if (ind < 0) {
        $('.lesson-collapse').collapse('toggle')
      }
      $(`#lesson-${ind}`).collapse('toggle')
    },
    checkBeforeRedirect (item) {
      if (item.is_updating) {
        let message = 'Video'
        if (item.type === 'PDF') {
          message = 'Tài liệu'
        } else if (item.type === 'EXAM') {
          message = 'Bài tập'
        }
        this.$notify.warning(message + ' đang được cập nhật')
        return
      }
      if (!this.user) {
        this.$bvModal.show('modal-login')
        this.$notify.warning('Bạn cần dăng nhập để xem video này')
        return
      }
      this.$helper.checkUserBuySub(this, item.is_free, '/video/' + item.slug, 'Bạn cần mua gói thành viên để xem video này')
    },
    checkLiveStream (item) {
      if (!this.user) {
        this.$bvModal.show('login')
        return
      }
      livestreamResource.list({ slug: item.slug })
        .then(({ data }) => {
          if (data.started) {
            if (!data.is_playback && data.ended) {
              this.$notify.warning('Buổi Live đã kết thúc. Bạn vui lòng đợi để xem lại buổi Live.')
              return
            }
            this.$router.push('/tai-lieu/live/' + item.slug)
          } else {
            this.$notify.warning('Buổi Live chưa bắt đầu')
          }
        })
        .catch((error) => {
          this.$notify.warning(error.response.data.message)
        })
      // if (item.started && !item.ended) {
      //   if (!this.user) {
      //     this.$bvModal.show('login')
      //     return
      //   }
      //   this.$helper.checkUserBuySub(this, item, '/thu-vien/live/' + item.slug, 'Bạn cần mua gói thành viên để tham gia cuộc livestream này')
      // } else if (!item.started) {
      //   this.$notify.warning('Buổi livestream chưa bắt đầu')
      // } else {
      //   if (item.is_playback) {
      //     this.$helper.checkUserBuySub(this, item, '/thu-vien/live/' + item.slug, 'Bạn cần mua gói thành viên để xem video này')
      //     return
      //   }
      //   this.$notify.warning('Buổi livestream đã kết thúc')
      // }
    },
    async viewExam (item) {
      if (!this.user) {
        this.$router.push('/trac-nghiem/' + item.slug)
        // const url = process.env.NUXT_ENV_WEB_URL + '/trac-nghiem/' + item.slug
        // window.open(url, '_blank').focus()
      }
      if (item.is_updating) {
        let message = 'Video'
        if (item.type === 'PDF') {
          message = 'Tài liệu'
        } else if (item.type === 'EXAM') {
          message = 'Bài tập'
        }
        this.$notify.warning(message + ' đang được cập nhật')
        return
      }
      if (item.is_complete) {
        this.$router.push('/trac-nghiem/ket-qua/' + item.slug)
        // const url = process.env.NUXT_ENV_WEB_URL + '/trac-nghiem/ket-qua/' + item.slug
        // window.open(url, '_blank').focus()
        return
      }
      const { data } = await checkAllow({
        class_id: item.class_id,
        type: 'EXAM',
        object_id: item.id
      })
      if (data.quiz === 0) {
        this.$store.dispatch('showStopModal', {
          type: 'EXAM',
          classId: item.class_id,
          dataFree: data.quiz_free
        })
      } else {
        this.$router.push('/trac-nghiem/' + item.slug)
        // const url = process.env.NUXT_ENV_WEB_URL + '/trac-nghiem/' + item.slug
        // window.open(url, '_blank').focus()
      }
    },
    startExam () {
      examStartResource.list({ exam_slug: this.examView.slug })
        .then(({ data }) => {
          this.examView = data
          this.showModalPreviewExam = false
          this.showModalViewExam = true
        })
        .catch((err) => {
          this.$notify.error(err.response.data.message)
        })
    },
    async viewDocument (item, type = 'view') {
      if (!this.user) {
        this.$router.push('/tai-lieu/' + item.slug)
        // const url = process.env.NUXT_ENV_WEB_URL + '/tai-lieu/' + item.slug
        // window.open(url, '_blank').focus()
        return
      }
      let message = 'Video'
      if (item.type === 'PDF') {
        message = 'Tài liệu'
      } else if (item.type === 'EXAM') {
        message = 'Bài tập'
      }
      if (item.is_updating) {
        this.$notify.warning(message + ' đang được cập nhật')
        return
      }
      const { data } = await checkAllow({
        class_id: item.class_id,
        type: 'DOCUMENT',
        object_id: item.id
      })
      if (data.document === 0) {
        this.$store.dispatch('showStopModal', {
          type: 'PDF',
          classId: item.class_id,
          dataFree: data.quiz_free
        })
      } else {
        fileResource.list({ slug: item.slug })
          .then(({ data }) => {
            if (type === 'view') {
              this.setUrlDocument(data)
            } else {
              this.downloadFile(data)
            }
          })
          .catch((err) => {
            this.$notify.error(err.response.data.message)
          })
      }
    },
    setUrlDocument (document) {
      this.$router.push('/tai-lieu/' + document.slug)
      // this.showModalViewDocument = true
      // this.documentView = document
      // const url = process.env.NUXT_ENV_WEB_URL + '/tai-lieu/' + document.slug
      // window.open(url, '_blank').focus()
    },
    downloadFile (item) {
      const element = document.createElement('a')
      element.setAttribute('href', item.url)
      element.setAttribute('target', '_blank')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-content-tab {
    .tab-title {
      line-height: 1.3;
      font-size: 1.25rem;
      @media (max-width: 1366px) {}
      @media (max-width: 1200px) {}
      @media (max-width: 992px) {}
      @media (max-width: 768px) {}
      @media (max-width: 576px) {
        font-size: 1rem;
      }
    }
    .content-selector {
      .selected-data {
        border: 1px solid #e1e7f5;
        border-radius: .625rem;
        font-size: 1rem;
        width: 11rem;
        padding: .5rem .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          padding: .5rem .5rem;
        }
      }
    }
    .each-unit-content {
      .unit-name {
        font-weight: 500;
        font-size: 1rem;
        padding-left: .5rem;
        border-left: .2rem solid #ffa216;
        margin-bottom: 1.25rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          margin-bottom: 1rem;
        }
      }
      .each-lesson {
        border: 1px solid #e1e7f5;
        border-radius: .5rem;
        margin-bottom: .75rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
        .btn-lesson-expand {
          border-radius: .5rem .5rem 0 0;
          font-weight: 600;
          font-size: 1rem;
          padding: 1.125rem .75rem;
          @media (max-width: 1366px) {}
          @media (max-width: 1200px) {}
          @media (max-width: 992px) {}
          @media (max-width: 768px) {}
          @media (max-width: 576px) {
            padding: .75rem .5rem;
          }
          .small-detail {
            font-weight: 400;
            color: #888888;
            font-size: .875rem;
            margin-left: .25rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {}
          }
          .expand-icon {
            transition: 0.2s ease;
          }
          &[aria-expanded='true'] {
            .expand-icon {
              transform: rotateZ(180deg);
            }
          }
        }
        .lesson-collapse {
          border-radius: 0;
          .lesson-part {
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid #e2e2e2;
            padding: .75rem;
            gap: .75rem;
            @media (max-width: 1366px) {}
            @media (max-width: 1200px) {}
            @media (max-width: 992px) {}
            @media (max-width: 768px) {}
            @media (max-width: 576px) {
              padding: .5rem;
              gap: .5rem;
            }
            .free-tag {
              top: .4rem;
              left: -1px;
              z-index: 1;
              max-width: 1.5rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {}
            }
            &.video-lesson {
              &.is-active{
                background-color: #ededed;
                .lesson-content{
                  font-weight: bold;
                }
              }
              .thumb-block {
                width: fit-content;
                .free-tag {
                  top: .4rem;
                  max-width: 1.85rem;
                  @media (max-width: 1366px) {}
                  @media (max-width: 1200px) {}
                  @media (max-width: 992px) {}
                  @media (max-width: 768px) {}
                  @media (max-width: 576px) {}
                }
                .video-thumb {
                  border-radius: .5rem;
                  object-fit: cover;
                  width: 7.5rem;
                  aspect-ratio: 150/87;
                  @media (max-width: 1366px) {}
                  @media (max-width: 1200px) {}
                  @media (max-width: 992px) {}
                  @media (max-width: 768px) {}
                  @media (max-width: 576px) {}
                  &.video{
                    width: 5.625rem;
                    @media (max-width: 1366px) {}
                    @media (max-width: 1200px) {}
                    @media (max-width: 992px) {}
                    @media (max-width: 768px) {}
                    @media (max-width: 576px) {}
                  }
                }
                .video-play-btn {
                  z-index: 2;
                }
                .lock-video{
                  background: rgba(0, 0, 0, 0.55);
                  backdrop-filter: blur(.35rem);
                  border-radius: 0px .25rem;
                  top: 0;
                  right: 0;
                }
              }
            }
            .lesson-content {
              width: 100%;
              padding-left: .625rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {}
              > span {
                gap: .5rem;
                @media (max-width: 1366px) {}
                @media (max-width: 1200px) {}
                @media (max-width: 992px) {}
                @media (max-width: 768px) {}
                @media (max-width: 576px) {}
              }
            }
            .lesson-status {
              gap: 1rem;
              font-size: .875rem;
              color: #888888;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {}
            }
            .extra-block {
              background: #f7f7f7;
              border-radius: .5rem;
              padding: .5rem 1rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {
                padding: .25rem .75rem;
              }
              .type-n-text {
                gap: .5rem;
              }
              .mcq-counter {
                font-weight: 500;
                font-size: 1.125rem;
                @media (max-width: 1366px) {}
                @media (max-width: 1200px) {}
                @media (max-width: 992px) {}
                @media (max-width: 768px) {}
                @media (max-width: 576px) {}
                .sc {
                  color: #7bbf6a;
                }
              }
            }
            .document{
              line-height: 3.125rem;
              @media (max-width: 1366px) {}
              @media (max-width: 1200px) {}
              @media (max-width: 992px) {}
              @media (max-width: 768px) {}
              @media (max-width: 576px) {
                line-height: 2rem;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  #modal-view-document{
    &___BV_modal_outer_{
      z-index: 9100 !important;
    }
    .modal-body{
      .close{
        right: .75rem;
        top:.75rem;
        z-index: 8;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
      .library-preview-file{
        height: 37.5rem;
        max-height: 37.5rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {
          height: 18.75rem;
        }
      }
    }
  }
  #modal-view-exam {
    &___BV_modal_outer_{
      z-index: 9100 !important;
    }
    .modal-body{
      .close{
        right: 1rem;
        top:1rem;
        z-index: 8;
        filter: brightness(0) invert(1);
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
  }
  #modal-preview-exam {
    &___BV_modal_outer_{
      z-index: 9100 !important;
    }
    .modal-body{
      .close{
        right: 1rem;
        top:1rem;
        z-index: 8;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
      .action{
        border-radius: 1.25rem;
        @media (max-width: 1366px) {}
        @media (max-width: 1200px) {}
        @media (max-width: 992px) {}
        @media (max-width: 768px) {}
        @media (max-width: 576px) {}
      }
    }
  }
</style>
